<h2 *ngIf="cards">Player cards</h2>
<p *ngIf="cards && expeditionMode">Add these player cards to your Barracks.</p>
<div class="row">
  <div *ngFor="let card of cards" class="border col-4 {{ getCardCssClass(card.type) }}">
    <div class="card-cost">
      {{ card.cost }}
    </div>
    <div class="card-name">
      {{ card.name }}
    </div>
    <div class="card-type">
      {{ getCardTypeLabel(card.type) }}
    </div>
  </div>
</div>
