import {Expansion} from '../expansion';
import {MarketCard} from '../market-card';
import {MarketCardType} from '../market-card-type';

export const OUTCASTS_CARDS: MarketCard[] = [
    {
        name: 'Molten Peridot',
        type: MarketCardType.Gem,
        cost: 3,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Petrified Phoenixium',
        type: MarketCardType.Gem,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Duplicating Sazite',
        type: MarketCardType.Gem,
        cost: 6,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Destiny Forger',
        type: MarketCardType.Relic,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Transmuter\'s Lens',
        type: MarketCardType.Relic,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Fortified Frost',
        type: MarketCardType.Spell,
        cost: 2,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Twisted Fang',
        type: MarketCardType.Spell,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Lighting Arrow',
        type: MarketCardType.Spell,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Tornado of Insight',
        type: MarketCardType.Spell,
        cost: 6,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Galvanized Sapphire',
        type: MarketCardType.Gem,
        cost: 4,
        expansion: Expansion.Outcasts,
        xaxos: true,
    },
    {
        name: 'Vigorous Sunstone',
        type: MarketCardType.Gem,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Carnivorous Roox',
        type: MarketCardType.Relic,
        cost: 3,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Infused Ignition',
        type: MarketCardType.Spell,
        cost: 4,
        expansion: Expansion.Outcasts,
        xaxos: true,
    },
    {
        name: 'Elongated Looq',
        type: MarketCardType.Gem,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Chain of Retrieval',
        type: MarketCardType.Relic,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Paired Storm',
        type: MarketCardType.Spell,
        cost: 3,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Arcane Relay',
        type: MarketCardType.Spell,
        cost: 8,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Helix of Amber',
        type: MarketCardType.Gem,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Reconstituting Circuit',
        type: MarketCardType.Relic,
        cost: 3,
        expansion: Expansion.Outcasts,
        xaxos: true,
    },
    {
        name: 'Unhinged Vortex',
        type: MarketCardType.Relic,
        cost: 7,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Radiant Conflux',
        type: MarketCardType.Spell,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Unstable Pyrite',
        type: MarketCardType.Gem,
        cost: 2,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Orb of the Deep',
        type: MarketCardType.Relic,
        cost: 2,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Rift Dagger',
        type: MarketCardType.Relic,
        cost: 3,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Bottled Sun',
        type: MarketCardType.Relic,
        cost: 6,
        expansion: Expansion.Outcasts,
        xaxos: true,
    },
    {
        name: 'Shattering Bolt',
        type: MarketCardType.Spell,
        cost: 4,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Unstable Rift',
        type: MarketCardType.Spell,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Gathered Will',
        type: MarketCardType.Spell,
        cost: 5,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Hasted Intellect',
        type: MarketCardType.Spell,
        cost: 6,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Swarm of Flame',
        type: MarketCardType.Spell,
        cost: 6,
        expansion: Expansion.Outcasts,
    },
    {
        name: 'Dizzying Burst',
        type: MarketCardType.Spell,
        cost: 8,
        expansion: Expansion.Outcasts,
    },
];
