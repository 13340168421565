import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-expedition-barracks-display',
  templateUrl: './start-expedition-barracks-display.component.html',
  styleUrls: ['./start-expedition-barracks-display.component.css']
})
export class StartExpeditionBarracksDisplayComponent {

  constructor() { }

}
