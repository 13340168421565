<ng-container *ngIf="gameMode === gameModeEnum.SingleGame">
  <app-single-game-market-display></app-single-game-market-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionUnknown">
  <app-expedition-unknown-display></app-expedition-unknown-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionStartBattle1">
  <app-start-expedition-barracks-display></app-start-expedition-barracks-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionStartBattle2 || gameMode === gameModeEnum.ExpeditionStartBattle3 || gameMode === gameModeEnum.ExpeditionStartBattle4">
  <app-expedition-start-battle-display></app-expedition-start-battle-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionWinBattle1 || gameMode === gameModeEnum.ExpeditionWinBattle2 || gameMode === gameModeEnum.ExpeditionWinBattle3">
  <app-expedition-win-display></app-expedition-win-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionWinBattle4">
  <app-finish-expedition-display></app-finish-expedition-display>
</ng-container>
<ng-container *ngIf="gameMode === gameModeEnum.ExpeditionLoseBattle1 || gameMode === gameModeEnum.ExpeditionLoseBattle2 || gameMode === gameModeEnum.ExpeditionLoseBattle3 || gameMode === gameModeEnum.ExpeditionLoseBattle4">
  <app-expedition-lose-display></app-expedition-lose-display>
</ng-container>

