<ng-container *ngIf="nemesis">
  <h2>Nemesis</h2>
  <div class="row">
    <div class="border offset-md-3 col-md-6 nemesis">
      <div class="nemesis-name">{{ nemesis.name }}</div>
      <div class="nemesis-rules">{{ nemesis.rules }}</div>
    </div>
  </div>
</ng-container>
<h2>Nemesis deck</h2>
<p>Set up the nemesis deck with the following basic and upgraded cards</p>
<table class="table table-sm">
<thead class="thead-dark">
  <tr>
    <th scope="col">Basic / Upgraded</th>
    <th scope="col">1 Player</th>
    <th scope="col">2 Players</th>
    <th scope="col">3 Players</th>
    <th scope="col">4 Players</th>
  </tr>
</thead>
<tbody *ngIf="battleNumber === 1">
  <tr>
    <th scope="row">Tier 1</th>
    <td>1 / 0</td>
    <td>3 / 0</td>
    <td>5 / 0</td>
    <td>8 / 0</td>
  </tr>
  <tr>
    <th scope="row">Tier 2</th>
    <td>3 / 0</td>
    <td>5 / 0</td>
    <td>6 / 0</td>
    <td>7 / 0</td>
  </tr>
  <tr>
    <th scope="row">Tier 3</th>
    <td>7 / 0</td>
    <td>7 / 0</td>
    <td>7 / 0</td>
    <td>7 / 0</td>
  </tr>
</tbody>
<tbody *ngIf="battleNumber === 2">
  <tr>
    <th scope="row">Tier 1</th>
    <td>0 / 1</td>
    <td>2 / 1</td>
    <td>4 / 1</td>
    <td>7 / 1</td>
  </tr>
  <tr>
    <th scope="row">Tier 2</th>
    <td>0 / 3</td>
    <td>2 / 3</td>
    <td>3 / 3</td>
    <td>4 / 3</td>
  </tr>
  <tr>
    <th scope="row">Tier 3</th>
    <td>4 / 3</td>
    <td>4 / 3</td>
    <td>4 / 3</td>
    <td>4 / 3</td>
  </tr>
</tbody>
<tbody *ngIf="battleNumber === 3">
  <tr>
    <th scope="row">Tier 1</th>
    <td>0 / 1</td>
    <td>1 / 2</td>
    <td>3 / 2</td>
    <td>6 / 2</td>
  </tr>
  <tr>
    <th scope="row">Tier 2</th>
    <td>0 / 3</td>
    <td>1 / 4</td>
    <td>2 / 4</td>
    <td>3 / 4</td>
  </tr>
  <tr>
    <th scope="row">Tier 3</th>
    <td>2 / 5</td>
    <td>2 / 5</td>
    <td>2 / 5</td>
    <td>2 / 5</td>
  </tr>
</tbody>
<tbody *ngIf="battleNumber === 4">
  <tr>
    <th scope="row">Tier 1</th>
    <td>0 / 1</td>
    <td>0 / 3</td>
    <td>2 / 3</td>
    <td>5 / 3</td>
  </tr>
  <tr>
    <th scope="row">Tier 2</th>
    <td>0 / 3</td>
    <td>0 / 5</td>
    <td>1 / 5</td>
    <td>2 / 5</td>
  </tr>
  <tr>
    <th scope="row">Tier 3</th>
    <td>0 / 7</td>
    <td>0 / 7</td>
    <td>0 / 7</td>
    <td>0 / 7</td>
  </tr>
</tbody>
</table>
