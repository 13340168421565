export enum Expansion {
  Base,
  TheDepths,
  TheNameless,
  WarEternal,
  TheVoid,
  TheOuterDark,
  Legacy,
  LegacyPromo,
  BuriedSecrets,
  DiceTowerPromo,
  TheNewAge,
  ShatteredDreams,
  TheNewAgePromo,
  TheAncients,
  IntoTheWild,
  Outcasts,
  OutcastsPromo,
  ReturnToGravehold,
  SouthernVillage,
}
