import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-game-market-display',
  templateUrl: './single-game-market-display.component.html',
  styleUrls: ['./single-game-market-display.component.css']
})
export class SingleGameMarketDisplayComponent {

  constructor() { }

}
