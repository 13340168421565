<app-market-selection></app-market-selection>
<h2>Nemesis setup</h2>
<table class="table table-sm">
<thead class="thead-dark">
  <tr>
    <th scope="col">Basic Nemesis Cards</th>
    <th scope="col">1 Player</th>
    <th scope="col">2 Players</th>
    <th scope="col">3 Players</th>
    <th scope="col">4 Players</th>
  </tr>
</thead>
<tbody>
  <tr>
    <th scope="row">Tier 1</th>
    <td>1</td>
    <td>3</td>
    <td>5</td>
    <td>8</td>
  </tr>
  <tr>
    <th scope="row">Tier 2</th>
    <td>3</td>
    <td>5</td>
    <td>6</td>
    <td>7</td>
  </tr>
  <tr>
    <th scope="row">Tier 3</th>
    <td>7</td>
    <td>7</td>
    <td>7</td>
    <td>7</td>
  </tr>
</tbody>
</table>
