<div class="container">
  <div class="row">
    <div class="col-md-4 offset-md-4 btn-group mb-1">
      <div class="btn-group" ngbDropdown >
      <button class="btn btn-secondary" ngbDropdownToggle type="button">{{ gameModeString }}</button>
        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.SingleGame)">Single game</button>
          <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionUnknown)">Expedition</button>
        </div>
      </div>
      <ng-container *ngIf="this.gameMode !== this.gameModeEnum.SingleGame">
        <div class="btn-group" ngbDropdown >
          <button class="btn btn-outline-secondary" ngbDropdownToggle type="button">{{ expeditionProgressString }}</button>
          <div ngbDropdownMenu class="expeditionDropdown">
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionStartBattle1)">Start Battle 1</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionWinBattle1)">Won Battle 1</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionLoseBattle1)">Lost Battle 1</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionStartBattle2)">Start Battle 2</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionWinBattle2)">Won Battle 2</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionLoseBattle2)">Lost Battle 2</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionStartBattle3)">Start Battle 3</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionWinBattle3)">Won Battle 3</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionLoseBattle3)">Lost Battle 3</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionStartBattle4)">Start Battle 4</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionWinBattle4)">Won Battle 4</button>
            <button ngbDropdownItem (click)="onGameModeClicked(gameModeEnum.ExpeditionLoseBattle4)">Lost Battle 4</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="row">
    <div class="offset-md-4 col-md-4 btn-group">
      <div class="btn-group" ngbDropdown autoClose="outside" >
        <button class="btn btn-secondary" ngbDropdownToggle type="button" id="market-source">{{ expansionChooser.shortLabel }}</button>
        <div ngbDropdownMenu>
          <app-expansion-chooser #expansionChooser></app-expansion-chooser>
        </div>
      </div>
      <button class="btn btn-outline-secondary" type="button" (click)="onGenerate()">Generate</button>
    </div>
  </div>
</div>
